import { onActivated, reactive } from "@vue/composition-api";
import ViewListModel from "../models/viewListModel";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const useListViewModel = (listVM: ViewListModel, autoLoadList = true) => {
  // listVMRef agar, semua nested object yang diakses
  // menjadi observables.
  // https://v3.vuejs.org/guide/reactivity.html#proxied-objects
  const listVMRef = reactive(listVM);

  const applyFilter = (filterMap?: Record<string, string>) =>
    listVMRef.applyFilter(filterMap);

  const onPageChange = async (page = 1) => listVMRef.onPageChange(page);

  if (autoLoadList) {
    // onActivated hanya akan dipanggil jika menggunakan keep-alive
    onActivated(() => {
      if (listVMRef.getRefreshRequest() !== "") {
        // ada permintaan refresh list (dengan initial filtering)
        listVMRef.applyFilter(listVMRef.refreshRequest.filterMap);
        listVMRef.resetRefreshRequest();
      } else if (listVMRef.list.length === 0) {
        // ketika listview pertama kali dipanggil, meload data list
        // asumsinya kalau list kosong, perlu fetch data dulu
        onPageChange();
      }
    });
  }

  const nextPage = () => listVMRef.nextPage();

  const prevPage = () => listVMRef.prevPage();

  return {
    // Data
    filterMap: listVMRef.filterMap,
    list: listVMRef.getList(),
    listVMRef,
    pagination: listVMRef.pagination,

    // Method
    applyFilter,
    nextPage,
    onPageChange,
    prevPage,
  };
};

export default useListViewModel;
