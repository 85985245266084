import { onMounted, reactive, watch } from "@vue/composition-api";
import router from "@/router";
import FormModel, { SaveContext } from "../models/formModel";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const useFormModel = (form: FormModel, id?: string) => {
  const formRef = reactive(form);
  const context = id ? SaveContext.Update : SaveContext.Create;

  // instance menjadi reactive, diakses dari formRef
  const instance = formRef.instance;

  // semua component yang berakhiran Form, Update, Detail,
  // atau Create di-exclude dari keep-alive
  // lihat core/layouts/MainAppLayout.vue
  onMounted(async () => {
    if (id) {
      formRef.isLoading = true;
      await formRef.fetch(id);
      formRef.isLoading = false;
    }

    // ditambahkan watch untuk kasus nested
    // perlu watch ketika selesai fetch
    // supaya tidak perlu setEdited ketika pertama kali load
    watch(instance, (newValue, oldValue) => {
      // jika perubahan karena save (create) dan id-nya diset
      // abaikan
      if (oldValue.id === null && newValue.id !== null) return;

      if (!formRef.validity.edited) {
        formRef.setEdited(true);
      }
    });
  });

  return {
    // Data
    formRef,
    instance,
    errorMap: formRef.errorMap, // reactive

    // Computed
    isValid: formRef.getIsValid(), // perlu dicek lagi!!!!!

    // Method
    cancel: () => router.go(-1),
    save: async () => await formRef.save(context),
    // unWatchInstance,
    validate: (value: string) => formRef.validate(value),
  };
};

export default useFormModel;
