import { computed, ComputedRef, reactive } from "@vue/composition-api";
import { camelCase, snakeCase } from "lodash";
import { API } from "../api";
import PlainModel from "./plainModel";
import router from "@/router";
import ViewModel from "./viewModel";

class Validity {
  edited = false;
  validated = false;
  // isValid digunakan di watch sebagai state dari save button enabled / disabled
  isValid: ComputedRef<boolean>;

  constructor() {
    const reactiveValidity = reactive(this);
    this.isValid = computed(() => {
      // computed hanya bisa memonitor ref / reactive
      return reactiveValidity.edited && reactiveValidity.validated;
    });
  }
}

export enum SaveContext {
  Create,
  Update,
}

export default abstract class FormModel extends ViewModel {
  validity = new Validity();
  allFields: Array<string> = [];
  initState: PlainModel;
  requiredFields: Array<string> = [];
  nonRequiredFields: Array<string> = [];
  errorMap: Record<string, string | Record<string, string> | null> = {
    // initialValue-nya: null untuk yang required, "" untuk yang notrequired
    nonFieldErrors: "",
  };
  setRefreshRequest: ((arg1: string) => void) | null;

  constructor(
    api: API,
    instance: PlainModel,
    requiredFields: Array<string>,
    nonRequiredFields: Array<string>,
    setRefreshRequest: ((arg1: string) => void) | null = null
  ) {
    super(api, instance);
    this.requiredFields = requiredFields;
    this.nonRequiredFields = nonRequiredFields;
    this.setRefreshRequest = setRefreshRequest;
    this.initState = Object.assign({}, this.instance);
    this.allFields = requiredFields.concat(nonRequiredFields);
    this.resetErrorMap(true);
  }

  async fetch(id: string, params = {} as Record<string, string>): Promise<void> {
    // exception sudah dihandle
    await super.fetch(id, params);
    this.initState = Object.assign({}, this.instance);
    this.resetErrorMap();
    this.validity.validated = true;
    this.validity.edited = false;
  }

  reset(): void {
    super.reset();
    this.initState = Object.assign({}, this.instance);
  }

  resetErrorMap(initial = false): void {
    const initValue: string | null = initial ? null : "";
    this.allFields = this.requiredFields.concat(this.nonRequiredFields);
    for (const fieldName of this.allFields) {
      const notRequired = this.nonRequiredFields.includes(fieldName);
      this.errorMap[fieldName] = notRequired ? "" : initValue;
    }
  }

  updateErrorMap(errorMap: Record<string, Array<string> | Record<string, Array<string>>>): void {
    // digunakan untuk load error dari response
    // error dari response menggunakan snake_case
    // error di vue app menggunakan camelCase
    this.validity.validated = false;
    this.validity.edited = false;
    // console.log(this.errorMap);
    for (const key of Object.keys(this.errorMap)) {
      const respKey = snakeCase(key);
      if (respKey in errorMap) {
        if (Array.isArray(errorMap[respKey])) {
          const errorList = errorMap[respKey] as Array<string>;
          this.errorMap[key] = errorList.join(". ");
        } else {
          const subError = errorMap[respKey] as Record<string, Array<string>>;
          for (const subKey of Object.keys(subError)) {
            // const respSubKey = snakeCase(subKey);
            const subMap = {} as Record<string, string>;
            const respSubKey = camelCase(subKey);
            subMap[respSubKey] = subError[subKey].join(". ");
            this.errorMap[key] = subMap;
          }
        }
      } else {
        this.errorMap[key] = "";
      }
    }
  }

  getIsValid(): ComputedRef<boolean> {
    return this.validity.isValid;
  }

  setEdited(value: boolean): void {
    this.errorMap.nonFieldErrors = "";
    this.validity.edited = value;
    this.calculateValidity();
  }

  validate(field?: string): void {
    // Jika field undefined maka cek semua field
    let checkFieldValues: Array<
      [string, string | number | boolean | Array<string | number | null> | null]
    > = [];
    const instance: Record<string, any> = Object.assign({}, this.instance);
    if (field !== undefined) {
      if (this.requiredFields.includes(field)) {
        checkFieldValues.push([field, instance[field]]);
      } else if (this.nonRequiredFields.includes(field)) {
        this.errorMap[field] = "";
      }
    } else {
      checkFieldValues = Object.entries(instance);
    }

    for (const [fieldName, value] of checkFieldValues) {
      if (!this.requiredFields.includes(fieldName)) continue;
      let calcValue = value;
      if (Array.isArray(value) && value.length == 0) {
        calcValue = null;
      }
      if (typeof calcValue === "boolean") {
        this.errorMap[fieldName] = "";
      } else if (typeof calcValue === "number") {
        this.errorMap[fieldName] = "";
      } else {
        this.errorMap[fieldName] = calcValue ? "" : "Harus diisi.";
      }
    }
    this.calculateValidity();
  }

  calculateValidity(): void {
    // calculate validity mengecek pesan error,
    // karena bisa saja datanya valid secara frontend,
    // tapi tidak valid secara API. Contoh: duplikat, dll!
    const vals = Object.values(this.errorMap);
    this.validity.validated = vals.every((txt) => txt === "");
    // console.log("calculateValidity");
    // console.log(this.validity);
    // console.log(this.errorMap);
  }

  getPayload(): Record<string, any> {
    // digunakan untuk mendapatkan model object yang berisi data
    // untuk dikirimkan ke API
    const payload: Record<string, any> = {};
    const initStateRec = this.initState as Record<string, any>;
    for (const [fieldName, value] of Object.entries(this.instance)) {
      const initVal = initStateRec[fieldName];
      // hanya mengambil data yang berubah saja
      if (initVal !== value) {
        let adjustedValue;
        if (Array.isArray(value)) {
          adjustedValue = value.map((obj) => obj.id? obj.id : obj);
        } else {
          adjustedValue = typeof value === "object" ? value?.id ?? null : value;
        }
        // convert to snake_case
        const sField = snakeCase(fieldName);
        payload[sField] = adjustedValue;
      }
    }
    return payload;
  }

  async save(
    context: SaveContext = SaveContext.Create,
    autoGoBack = true,
    commit = true,
    params: Record<string, any> = {}
  ): Promise<void> {
    try {
      let respData: Record<string, any>;
      if (context === SaveContext.Create) {
        respData = await this.create(commit, params);
        // jika sukses akan ada respData.id
        // console.log(respData);
        if (respData.id) this.instance.id = respData.id;
      } else {
        respData = await this.update(this.instance.id ?? "", commit, params);
      }
      // penanda error http400, adalah adanya field error
      if ("error" in respData) {
        delete respData.error;
        // console.log(respData);
        this.updateErrorMap(respData);
      } else {
        // debugger;
        if (this.setRefreshRequest) {
          // request update listview
          this.setRefreshRequest(router.currentRoute.name ?? "");
        }
        if (autoGoBack) router.go(-1);
      }
    } catch (error) {
      /* do nothing */
    }
  }

  private async create(commit = true, params: Record<string, any> = {}) {
    params.commit = commit;
    return await this.api.create(this.getPayload(), params);
  }

  private async update(
    id: string,
    commit = true,
    params: Record<string, any> = {}
  ) {
    params.commit = commit;
    return await this.api.update(id, this.getPayload(), params);
  }
}
